.btn{
    min-width: 25vw;
    max-width: 25vw;
    min-height: 6vh;
    font-family: 'Times New Roman', Times, serif;
    justify-content: center;
    font-size: 3vh;
    background-color: rgb(7, 7, 7);
    color: white;
    font-weight: bold;
}
.btn:hover{
    background-color: rgb(50, 50, 145);
    color: white;
}
.btn:active{
    background-color:rgba(194, 207, 5, 0.514);
}

.btn_mobile{
    min-width: 25vw;
    max-width: 25vw;
    min-height: 6vh;
    font-family: 'Times New Roman', Times, serif;
    justify-content: center;
    font-size: 5vw;
    background-color: rgb(7, 7, 7);
    color: white;
    font-weight: bold;
}
.btn_mobile:hover{
    background-color: rgb(50, 50, 145);
    color: white;
}
.btn_mobile:active{
    background-color:rgba(0, 81, 255, 0.514);
}

.Tabs{
    position: fixed;
    display: flex;
    flex-direction: row;
    background-color: rgb(61, 38, 90);
    min-height: 6vh;
    min-width: 100vw;
}
.Tabs-mobile{
    position: fixed;
    display: flex;
    flex-direction: row;
    background-color: rgb(68, 28, 1);
    min-height: 6vh;
    min-width: 100vw;
    max-width: 100vw;
}
.ButtonSize{
    min-width: 25vw;
    max-width: 25vw;
    min-height: 6vh;
}