.TextPhoto{
  
  display: flex;
  flex-direction: row;
}
.Text{
  border-style: solid;
  display: flex;
  flex-direction: column;
}
.Background {
  background-color: rgb(3, 6, 22);
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
  overflow: hidden;
}


.Border{
  min-height: 6vh;
  min-width: 100vw;
}
