.Background2{
    background-color: rgb(3, 6, 22);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: 'Times New Roman', Times, serif;
}
.Information{
    color: white;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    flex-direction: row;
    top: 60px;
}

.Content{
    display: flex;
    flex-direction: column;
    margin-bottom: -.1%;
    min-width: 25vw;
    max-width: 25vw; 
    text-wrap: balance;
}

.Logo{
    display: flex;
    flex-direction: column;
    min-width: 25vw;
    max-width: 25vw; 
}

.MLogo{
    display: flex;
    flex-direction: column;
    min-width: 35vw;
    max-width: 35vw; 

}

.MFormat{
    display: flex;
    flex-direction: row;
}
.MText{
    display: flex;
    flex-direction: column;
    min-width: 50vw;
    text-wrap: balance;
}

.MText2{
    display: flex;
    flex-direction: column;
    min-width: 25vw;
    text-wrap: balance;
}

.MText3{
    display: flex;
    flex-direction: column;
    min-width: 33vw;
    
}

.MText4{
    display: flex;
    flex-direction: column;
    min-width: 15vw;
    
}