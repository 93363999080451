.Name {
    font-weight: normal;  
}

.Greatings{
    margin: -3px;
    font-family: 'Times New Roman', Times, serif;
}

.HomeFormat{
    display: flex;
    flex-direction: row;
    margin-right: 2%;
    
}

.HomeText{
    font-size: calc(20px + 2vmin);
    display: flex;
    flex-direction: column;
    min-width: 45vw;
    align-items: center;
    justify-content: center;
}
.ContactBackground{
    display: flex;
    flex-direction: column;
    background-color: #021b36;
    color: rgb(255, 255, 255);
}

.ContactsFormat{
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: calc(10px + 2vmin);
    max-width: 100vw;

}
.LeftSide{
    display: flex;
    min-width: 50%;
    
}
.LeftLinkContainer {
    min-width: 50%; /* Ensure the container takes up the same space */
    display: flex; /* Use flex to align the link */


}

.LeftLink{
    display: inline-block; /* Ensure the link only takes the width of its content */
    color: white;
    white-space: nowrap; /* Prevent wrapping of text */
}
.LeftLink:hover{
    color: blue;
}
.RightSide{
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 50%;
}
.RightLinkContainer {
    min-width: 50%; /* Ensure the container takes up the same space */
    display: flex; /* Use flex to align the link */
}

.RightLink{
    display: inline-block; /* Ensure the link only takes the width of its content */
    color: white;
    white-space: nowrap; /* Prevent wrapping of text */
}
.RightLink:hover{
    color: blue;
    min-width: auto;
}
.ContactsFormatMobile{
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    max-width: 100vw;

}
.pEmailMobile{
    display: flex;
    flex-direction: row;
    position: relative;
    min-width: 50%;
    
}
.sEmailMobile{
    display: flex;
    flex-direction: row;
    position: relative;
    min-width: 50%;
}
